@font-face {
  font-family: 'Moderat JIT';
  src: url('./ModeratJIT-Regular.woff2') format('woff2'),
    url('./ModeratJIT-Regular.woff') format('woff'),
    url('./ModeratJIT-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Moderat JIT';
  src: url('./ModeratJIT-Light.woff2') format('woff2'),
    url('./ModeratJIT-Light.woff') format('woff'),
    url('./ModeratJIT-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Moderat JIT';
  src: url('./ModeratJIT-Thin.woff2') format('woff2'),
    url('./ModeratJIT-Thin.woff') format('woff'),
    url('./ModeratJIT-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Moderat JIT';
  src: url('./ModeratJIT-Bold.woff2') format('woff2'),
    url('./ModeratJIT-Bold.woff') format('woff'),
    url('./ModeratJIT-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
